import { preloadVideo } from "@remotion/preload";
import { indexOf, set, split } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FaArrowDown, FaArrowLeft, FaArrowUp, FaCog, FaEdit, FaRobot } from "react-icons/fa";
import { AbsoluteFill } from "remotion";
import { v4 as uuidv4 } from "uuid";
import axios from "./api";
import whooshfast from "./assets/sounds/whoosh-fast.mp3";
import whooshfast2 from "./assets/sounds/whoosh-fast2.mp3";
import whooshfast3 from "./assets/sounds/whoosh-fast3.mp3";
import whooshlong from "./assets/sounds/whoosh-long.mp3";
import whooshmedium from "./assets/sounds/whoosh-medium.mp3";
import whooshmedium2 from "./assets/sounds/whoosh-medium2.mp3";
import filmburn from "./assets/transitions/film_burn.mp4";
import vintage from "./assets/transitions/vintage.mp4";
import { FootageZoom } from "./components/panel/subtitles/FootageZoom";

import { shortVideoStore } from "./Store/shortVideoStore";
import { FootageAI } from "./components/FootageAI";
import { FootageBackground, backgroundOptions } from "./components/FootageBackground";

import { userStore } from "./Store/userStore";
import { FootageTransitions, defaultTransitions } from "./components/FootageTransitions";
import { FootageUpload } from "./components/FootageUpload";
import { FootageSoundEffects, defaultSoundEffects } from "./components/FootageSoundEffects";
import { FootageLayout } from "./components/FootageLayout";

const FootageMenu = (props, ref) => {
	const { seekTo, setSelectZoomFootageId } = props;

	const {
		globalActions,
		setGlobalActions,
		fps,
		duration: videoTime,
		videoLink,
		footages,
		setFootages,
		subtitles,
		addHistory,
		updateFootage: updateFootageAction,
	} = shortVideoStore((state) => state);

	const updateFootage = (footage) => {
		addHistory("footages", footages);
		updateFootageAction(footage);
	};

	const { user, session } = userStore((state) => state);
	const plan = user?.plan;

	const [showMenu, setShowMenu] = useState(false);
	const [startSubtitle, setStartSubtitle] = useState(null);
	const [endSubtitle, setEndSubtitle] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [videos, setVideos] = useState([]);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [statusMessage, setStatusMessage] = useState("");
	const [startIndex, setStartIndex] = useState(0);
	const [loadingAi, setLoadingAi] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isGenerating, setIsGenerating] = useState(false);
	const [showLayout, setShowLayout] = useState(null);
	const [optionsFootage, setOptionsFootage] = useState({
		clip: true,
		zoom: true,
		transition: true,
		background: false,
		ai: false,
	});
	const [transcript, setTranscript] = useState("");

	useEffect(() => {
		if (subtitles.length > 0) {
			const transcript = getTranscriptFromSubtitles(subtitles);
			setTranscript(transcript);
		}
	}, [subtitles]);

	const whooshModels = [
		{
			id: 0,
			name: "Swift Breeze",
			description: "Fast whoosh sound effect.",
			whooshStartTime: -0.1,
			sound: "d22jtc54nbh1lg.cloudfront.net/whoosh-fast.mp3",
			emoji: "💨",
		},
		{
			id: 1,
			name: "Rapid Gust",
			description: "Fast whoosh sound effect.",
			whooshStartTime: -0.12,
			sound: "d22jtc54nbh1lg.cloudfront.net/whoosh-fast2.mp3",
			emoji: "🌪️",
		},
		{
			id: 2,
			name: "Quick Zephyr",
			description: "Fast whoosh sound effect.",
			whooshStartTime: -0.1,
			sound: "d22jtc54nbh1lg.cloudfront.net/whoosh-fast3.mp3",
			emoji: "🌀",
		},
		{
			id: 3,
			name: "Moderate Gale",
			description: "Medium whoosh sound effect.",
			whooshStartTime: -0.1,
			sound: "d22jtc54nbh1lg.cloudfront.net/whoosh-medium.mp3",
			emoji: "🌬️",
		},
		{
			id: 4,
			name: "Steady Draft",
			description: "Medium whoosh sound effect.",
			whooshStartTime: -0.1,
			sound: "d22jtc54nbh1lg.cloudfront.net/whoosh-medium2.mp3",
			emoji: "🍃",
		},
		{
			id: 5,
			name: "Prolonged Wind",
			description: "Long whoosh sound effect.",
			whooshStartTime: -0.1,
			sound: "d22jtc54nbh1lg.cloudfront.net/whoosh-long.mp3",
			emoji: "🌾",
		},
	];

	const zoomModels = [
		{
			id: 0,
			icon: "✨",
			name: "Default Zoom",
			description: "Captures and sustains interest.",
			zoomIntensity: 1.15,
			zoomIn: 200,
			zoomOut: 200,
			whoosh: "https://d22jtc54nbh1lg.cloudfront.net/whoosh-medium.mp3",
			trackerPositionX: 0,
			trackerPositionY: 0,
		},
		{
			id: 1,
			icon: "👀",
			name: "Progressive Zoom",
			description: "Intense detail and clarity.",
			zoomIntensity: 1.2,
			zoomIn: "max",
			zoomOut: 0,
			whoosh: null,
			trackerPositionX: 0,
			trackerPositionY: 0,
		},
		{
			id: 2,
			icon: "⚡",
			name: "Fast Zoom",
			description: "Grabs attention instantly.",
			zoomIntensity: 1.1,
			zoomIn: 120,
			zoomOut: 120,
			whoosh: "https://d22jtc54nbh1lg.cloudfront.net/whoosh-fast2.mp3",
			trackerPositionX: 0,
			trackerPositionY: 0,
		},
		// {
		// 	id: 3,
		// 	icon: "⚡",
		// 	name: "Progessive Zoom-Out",
		// 	description: "Grabs attention instantly.",
		// 	zoomIntensity: 1.1,
		// 	zoomIn: 60,
		// 	zoomOut: "max",
		// 	whoosh: null,
		// 	trackerPositionX: 0,
		// 	trackerPositionY: -100,
		// },
		{
			id: 3,
			icon: "⚡",
			name: "Instant Zoom",
			description: "Grabs attention instantly.",
			zoomIntensity: 1.05,
			zoomIn: 0,
			zoomOut: 0,
			whoosh: null,
			trackerPositionX: 0,
			trackerPositionY: 0,
		},
	];

	function DropdownSelection({ footage, duration }) {
		const [modelMenu, setModelMenu] = useState(false);
		const toggleZoomRef = useRef(null);
		const modelRef = useRef(null);

		const handleOutsideClick = (e) => {
			if (
				modelRef.current &&
				!modelRef.current.contains(e.target) &&
				!toggleZoomRef.current.contains(e.target)
			) {
				setModelMenu(false);
			}
		};

		useEffect(() => {
			document.addEventListener("click", handleOutsideClick);
			return () => {
				document.removeEventListener("click", handleOutsideClick);
			};
		}, []);

		const models = [
			{
				name: "None",
				key: "None",
				desc: "Your own content",
			},
			{
				name: "🔎 Zoom",
				key: "Zoom",
				desc: "Add dynamic zoom",
			},
			{
				name: "📸 Clip",
				key: "Clip",
				desc: "Royalty free footages",
			},
		];

		return (
			<div className="flex gap-1">
				<div className="relative w-full">
					<div
						ref={toggleZoomRef}
						className="text-sm text-gray-200 font-semibold cursor-pointer flex items-center"
					>
						<button
							onClick={() => {
								seekTo(footage.start);
							}}
							className="text-[10px] w-[80px] text-gray-300 bg-gray-800 bg-opacity-50 rounded px-1 py-1 hover:text-gray-100 mr-2"
						>
							{duration}
						</button>
						<div
							onClick={() => {
								setModelMenu(!modelMenu);
							}}
							className="flex items-center"
						>
							<span>{models.find((model) => model.key === footage.type)?.key || "None"}</span>
							<svg
								className="w-2.5 h-2.5 ml-1.5"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 10 6"
							>
								<path
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="m1 1 4 4 4-4"
								/>
							</svg>
						</div>
					</div>
					<div
						id="dropdownToggle"
						className={`z-10 absolute top-full mt-1 translate-x-12 w-48 divide-y rounded-lg shadow bg-gray-800 border-[1px] border-gray-700 divide-gray-700 ${
							modelMenu ? "block" : "hidden"
						}`}
						ref={modelRef}
					>
						<ul className="text-sm text-gray-200 space-y-1 text-left">
							{models.map((model) => {
								let hasAccess = true;
								if (model?.plan) {
									if (model.plan !== plan) {
										hasAccess = false;
									}
								}
								return (
									<li
										key={model.key}
										className={`${
											hasAccess
												? "hover:bg-gray-700 hover:cursor-pointer"
												: "hover:cursor-not-allowed"
										} flex justify-between items-start py-1 px-2`}
										onClick={() => {
											if (!hasAccess) {
												toast.error("You need to upgrade to access this feature.");
												return;
											}
											setModelMenu(false);
											if (model.key === "Zoom") {
												const modelToUse = footage?.zoomConfig?.model || 0;
												const path = zoomModels[modelToUse]?.whoosh;
												let soundEffectConfig = null;
												if (path) {
													soundEffectConfig = {
														volume: 0.5,
														path: path,
													};
												}
												updateFootage({
													...footage,
													type: model.key,
													transitionConfig: null,
													zoomConfig: {
														model: modelToUse,
														zoomIntensity: zoomModels[modelToUse].zoomIntensity,
														zoomIn: zoomModels[modelToUse].zoomIn,
														zoomOut: zoomModels[modelToUse].zoomOut,
														trackerPositionX: zoomModels[modelToUse].trackerPositionX,
														trackerPositionY: zoomModels[modelToUse].trackerPositionY,
													},
													soundEffectConfig: soundEffectConfig,
												});
											} else {
												let transitionConfig = null;
												const index = footages.indexOf(footage);
												const lastConfig = footages[index - 1]?.type;
												let soundEffectConfig = null;
												if (
													["Clip", "AI", "Background"].includes(model.key) &&
													!["Clip", "AI", "Background"].includes(lastConfig)
												) {
													transitionConfig =
														footage?.transitionConfig ??
														defaultTransitions[
															Math.floor(Math.random() * (defaultTransitions.length - 1)) + 1
														];
													const randomPath = transitionConfig?.sound
														? transitionConfig.sound[
																Math.floor(Math.random() * transitionConfig.sound.length)
														  ]
														: undefined;
													soundEffectConfig = {
														volume: 0.5,
														path: randomPath,
													};
												}
												updateFootage({
													...footage,
													type: model.key,
													soundEffectConfig: soundEffectConfig,
													transitionConfig: transitionConfig,
													backgrounds: {
														...footage.backgrounds,
														imagePath: footage?.backgrounds?.imagePath ?? "",
														path:
															footage?.backgrounds?.path?.length > 0
																? footage.backgrounds.path
																: backgroundOptions[0].url,
														top: footage?.backgrounds?.top ?? 50,
														zoom: 0.6,
													},
												});
											}
										}}
									>
										<div className={`flex flex-col relative w-full`}>
											<span
												className={`text-xs font-medium text-gray-300 mb-[2px] ${
													hasAccess ? "opacity-100" : "opacity-50"
												}`}
											>
												{model.name}
											</span>
											<span
												className={`text-xs text-gray-400 ${
													hasAccess ? "opacity-100" : "opacity-50"
												}`}
											>
												{model.desc}
											</span>
											{model?.plan && (
												<span className="absolute text-[8.5px] right-0 top-0 px-[0.4rem] text-white bg-blue-500/70  rounded-xl">
													{model.plan.toUpperCase()}
												</span>
											)}
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		);
	}

	const getTranscriptFromSubtitles = (subtitles) => {
		//no break line
		let transcript = "";
		subtitles.forEach((subtitle) => {
			transcript += subtitle.words.map((word) => word.word).join(" ") + " ";
		});
		return transcript;
	};

	function ensureMinimumDuration(timestamps, minDuration) {
		// Séparer la chaîne en segments
		const segments = timestamps.match(/\[(\d+(\.\d+)?),(\d+(\.\d+)?)\]/g);

		if (segments) {
			let totalDuration = 0;
			let start = parseFloat(segments[0].match(/\[(\d+(\.\d+)?),/)[1]);
			let end;
			let outputSegments = [];

			for (let segment of segments) {
				end = parseFloat(segment.match(/,(\d+(\.\d+)?)\]/)[1]);
				totalDuration += end - start;

				if (totalDuration >= minDuration) {
					outputSegments.push(`[${start},${end}]`);
					start = end;
					totalDuration = 0;
				}
			}

			if (totalDuration > 0 && totalDuration < minDuration) {
				if (outputSegments.length > 0) {
					// Récupérer le dernier segment et étendre sa durée
					const lastSegment = outputSegments.pop();
					const lastSegmentStart = lastSegment.match(/\[(\d+(\.\d+)?),/)[1];
					outputSegments.push(`[${lastSegmentStart},${end}]`);
				} else {
					// Ajouter le segment à outputSegments si aucun autre segment n'a été ajouté avant
					outputSegments.push(`[${start},${end}]`);
				}
			}

			return outputSegments.join(" ");
		}

		return "";
	}

	const normalizeText = (text) => {
		return text
			.replace(/[^a-zA-Z0-9\s]/g, "")
			.replace(/\s+/g, " ")
			.toLowerCase()
			.trim();
	};

	const tokenizeSentences = (text) => {
		const trim = (array) => {
			while (array[array.length - 1] === "") array.pop();
			while (array[0] === "") array.shift();
			return array;
		};

		let tokens = text.match(
			/(?<=\s+|^)["'‘“'"[({⟨]?(.*?[.?!…]|.+)(\s[.?!…])*["'’”'"\])}⟩]?(?=\s+|$)/g
		);

		if (!tokens) return [text];

		tokens = tokens.map((token) => token.trim());

		return trim(tokens);
	};

	const splitText = (subtitles) => {
		let lastDuration = 0;
		let sentences = [];
		let currentSentence = [];
		for (let subtitle of subtitles) {
			const words = subtitle.words.map((word) => word.word).join(" ");
			const duration = subtitle.end - subtitle.start;
			if (lastDuration <= 3) {
				currentSentence.push(words);
			} else {
				sentences.push(currentSentence.join(" ")); // Ajoute la phrase actuelle avant de commencer une nouvelle
				currentSentence = [words]; // Commence une nouvelle phrase avec les mots actuels
				lastDuration = duration; // Commence le comptage de la durée pour la nouvelle phrase
				continue; // Passe au prochain sous-titre
			}
			lastDuration += duration;
		}

		// Après la boucle, vérifie si currentSentence a des mots non ajoutés.
		if (currentSentence.length > 0) {
			sentences.push(currentSentence.join(" "));
		}

		return sentences;
	};

	const videoAlreadyAssigned = (currentLink, allLinks) => {
		for (let link of allLinks) {
			if (link === currentLink) {
				return true;
			}
		}
		return false;
	};

	const nextVideoUnassigned = (videos, allLinks) => {
		let selectedVideo = 0;
		if (videos.length === 0) return selectedVideo;
		for (let video of videos) {
			if (!videoAlreadyAssigned(video.url, allLinks)) {
				return selectedVideo;
			}
			selectedVideo++;
		}
		return selectedVideo;
	};

	const imageAlreadyAssigned = (currentLink, allLinks) => {
		for (let link of allLinks) {
			if (link === currentLink) {
				return true;
			}
		}
		return false;
	};

	const nextImageUnassigned = (images, allLinks) => {
		let selectedImage = 0;
		for (let image of images) {
			if (!imageAlreadyAssigned(image.url, allLinks)) {
				return selectedImage;
			}
			selectedImage++;
		}
		return selectedImage;
	};

	const generateFootage = async () => {
		setIsGenerating(true);
		const allSentences = splitText(subtitles);
		const response = await axios.post("/pexels/generate2", {
			allSentences: allSentences,
		});
		const datas = response.data?.body;

		let timestampsForData = {};
		if (!datas || datas.length === 0) {
			setIsGenerating(false);
			return;
		}
		if (datas) {
			let tempSubtitles = [...subtitles];
			let i = 0;
			let currentSubtitles = [];

			for (let data of datas) {
				const sentence = allSentences[i];

				while (tempSubtitles.length > 0) {
					let subtitle = tempSubtitles[0];
					let valueSub = subtitle.words.map((word) => word.word).join(" ");
					const normalizedSentence = normalizeText(sentence);
					const normalizedValueSub = normalizeText(valueSub);
					const includes = normalizedSentence.includes(normalizedValueSub);
					if (includes) {
						currentSubtitles.push(subtitle);
						tempSubtitles.shift();
					} else {
						break;
					}
				}
				if (currentSubtitles.length > 0) {
					let times = currentSubtitles
						.map((sub) => {
							let start = sub.start % 1 === 0 ? sub.start.toFixed(2) : sub.start;
							let end = sub.end % 1 === 0 ? sub.start.toFixed(2) : sub.end;
							return `[${start},${end}]`;
						})
						.join(" ");

					// Ajout des timestamps à l'objet timestampsForData
					timestampsForData[`DATA ${i}`] = {
						type: "None",
						timestamps: times,
						videos: data.videos,
						ai_images: [],
						images: data.images,
						backgrounds: {
							imagePath: "",
							path: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/grid.mp4",
							zoom: 0.6,
						},
						keywords: data.keywords,
						searchTerm: "",
						byUser: false,
					};

					//console.log(`${times} -----> ${sentence}`);
					currentSubtitles = [];
				} else {
					console.log("No matching subtitles found for this data.");
				}
				i++;
			}
		}

		let tempFootages = [];
		let allLinks = [];
		let allLiksImages = [];
		for (let [dataKey, dataValue] of Object.entries(timestampsForData)) {
			const allIntervals = ensureMinimumDuration(dataValue.timestamps, 2.5);
			const intervalsArray = allIntervals.split(" ");
			const videos = dataValue.videos;
			const images = dataValue.images;
			if (videos?.length === 0) {
				console.log("No videos found for this data.");
				continue;
			}
			for (let d of intervalsArray) {
				const timestampsString = d.substring(1, d.length - 1);
				const start = parseFloat(timestampsString.split(",")[0]);
				const end = parseFloat(timestampsString.split(",")[1]);

				if (typeof start !== "number" || typeof end !== "number" || start >= end) {
					console.log("Start or end are not instance of float or int");
					continue;
				}

				let selectedVideoIndex = nextVideoUnassigned(videos, allLinks);
				if (
					videos[selectedVideoIndex]?.url === undefined ||
					videos[selectedVideoIndex]?.url?.length === 0
				) {
					console.log(videos);
					console.log("No video url found for this data.");
					selectedVideoIndex = 0;
				}
				let link = videos[selectedVideoIndex].url;
				allLinks.push(link);

				let selectedImageIndex = nextImageUnassigned(images, allLiksImages);
				let linkImage;
				if (
					images[selectedImageIndex]?.url !== undefined &&
					images[selectedImageIndex]?.url?.length !== 0
				) {
					linkImage = images[selectedImageIndex].url;
					allLiksImages.push(linkImage);
				}

				// Précharger la vidéo
				// setLoadingVideos((prev) => [...prev, link]);
				// const { free, waitUntilDone } = prefetch(link);
				// waitUntilDone().then(() => {
				// 	console.log("Footage has finished prefetching");
				// 	setLoadingVideos((prev) => prev.filter((v) => v !== link));
				// });
				preloadVideo(link);
				const lastConfig = tempFootages[tempFootages.length - 1]?.type;
				const random = Math.random();
				let type = "None";

				if (optionsFootage.clip && optionsFootage.zoom) {
					if (lastConfig !== "Zoom" && random < 0.5) {
						type = "Zoom";
					} else {
						type = "Clip";
					}
				} else if (optionsFootage.clip && !optionsFootage.zoom) {
					type = "Clip";
				} else if (!optionsFootage.clip && optionsFootage.zoom) {
					if (lastConfig !== "Zoom") {
						type = "Zoom";
					}
				}

				const numberZoom =
					tempFootages.length > 0
						? tempFootages.filter((footage) => footage.type === "Zoom").length
						: 0;
				const zoomModel = numberZoom % zoomModels.length;
				let transitionModel = null;
				let soundEffectConfig = null;

				if (type === "Clip" && optionsFootage.transition && lastConfig !== "Clip") {
					transitionModel =
						defaultTransitions[Math.floor(Math.random() * (defaultTransitions.length - 1)) + 1];
					const randomPath = transitionModel?.sound
						? transitionModel.sound[Math.floor(Math.random() * transitionModel.sound.length)]
						: undefined;
					soundEffectConfig = {
						volume: 0.5,
						path: randomPath,
					};
				}

				const newRandom = Math.random();
				if (type === "Clip" && optionsFootage.ai) {
					if (newRandom < 0.5 && lastConfig !== "AI" && plan === "pro") {
						type = "AI";
					}
				}

				if (type === "Clip" && optionsFootage.background) {
					if (newRandom < 0.5 && lastConfig !== "Background") {
						type = "Background";
					}
				}

				if (type === "Zoom") {
					transitionModel = null;
					if (zoomModels[zoomModel].whoosh) {
						soundEffectConfig = {
							volume: 0.5,
							path: zoomModels[zoomModel].whoosh,
						};
					}
				}

				if (soundEffectConfig?.path) {
					let foundSoundEffect = null;
					for (const category of Object.values(defaultSoundEffects)) {
						foundSoundEffect = category.find((effect) => effect.path === soundEffectConfig.path);
						if (foundSoundEffect) break;
					}
					const delay = foundSoundEffect?.delay || 0;

					soundEffectConfig = {
						...soundEffectConfig,
						delay: delay,
					};
				}

				const newBackgrounds = linkImage
					? {
							imagePath: linkImage,
							path: backgroundOptions[Math.floor(Math.random() * (4 - 1))].url,
							top: 50,
							zoom: 0.6,
					  }
					: {
							imagePath: "",
							path: backgroundOptions[Math.floor(Math.random() * (4 - 1))].url,
							top: 50,
							zoom: 0.6,
					  };

				tempFootages.push({
					id: uuidv4(),
					keywords: dataValue.keywords,
					searchTerm: "",
					videos: videos,
					ai_images: [],
					images: images,
					backgrounds: newBackgrounds,
					selectedVideo: selectedVideoIndex,
					displayStart: 0,
					start: start,
					end: end,
					visible: true,
					type: type,
					transitionConfig: transitionModel,
					soundConfig: null,
					byUser: false,
					zoomConfig: {
						model: zoomModel,
						zoomIntensity: zoomModels[zoomModel].zoomIntensity,
						zoomIn: zoomModels[zoomModel].zoomIn,
						zoomOut: zoomModels[zoomModel].zoomOut,
						trackerPositionX: zoomModels[zoomModel].trackerPositionX,
						trackerPositionY: zoomModels[zoomModel].trackerPositionY,
					},
					soundEffectConfig: soundEffectConfig,
				});

				selectedVideoIndex++;
			}
		}
		setFootages(tempFootages);

		setIsGenerating(false);
	};

	useEffect(() => {
		if (subtitles.length > 0 && footages.length > 0) {
			adjustFootagesForSubtitle(footages, subtitles);
		}
	}, [subtitles]);

	function adjustFootagesForSubtitle(footages, subtitles) {
		let updatedFootages = [...footages];

		// Parcourir les sous-titres et adapter les footages
		subtitles.forEach((subtitle, index) => {
			for (let i = 0; i < updatedFootages.length; i++) {
				if (subtitle.start >= updatedFootages[i].start && subtitle.end <= updatedFootages[i].end) {
					// le sous-titre est bien couvert par le footage, pas d'ajustement nécessaire
					break;
				} else if (
					subtitle.start < updatedFootages[i].start &&
					subtitle.end > updatedFootages[i].start &&
					subtitle.end <= updatedFootages[i].end
				) {
					// le sous-titre commence avant le footage, nous ajustons le début du footage
					updatedFootages[i].start = subtitle.start;
					if (i > 0) {
						updatedFootages[i - 1].end = subtitle.start;
					}
					break;
				} else if (
					subtitle.start < updatedFootages[i].end &&
					subtitle.end > updatedFootages[i].end
				) {
					// le sous-titre n'est pas complètement couvert par le footage, donc nous ajustons la fin du footage
					updatedFootages[i].end = subtitle.end;
					if (i + 1 < updatedFootages.length) {
						updatedFootages[i + 1].start = subtitle.end;
					}
					break;
				}
			}
		});

		// Ajustement des footages qui se chevauchent
		for (let i = 0; i < updatedFootages.length - 1; i++) {
			if (updatedFootages[i].end > updatedFootages[i + 1].start) {
				updatedFootages[i + 1].start = updatedFootages[i].end;
			}
		}

		setFootages(updatedFootages);
	}

	const getOverlappingFootages = (start, end) => {
		return footages.filter((footage) => {
			return footage.start < end && footage.end > start;
		});
	};

	const addToFootages = (videos, start, keywords) => {
		if (selectedVideo && startSubtitle && endSubtitle) {
			addHistory("footages", footages);
			const index = videos.indexOf(selectedVideo);

			// Add the new footage
			const newFootage = {
				id: uuidv4(),
				type: "None",
				videos: videos,
				ai_images: [],
				images: [],
				backgrounds: {
					imagePath: "",
					path: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/grid.mp4",
					zoom: 0.6,
				},
				selectedVideo: index,
				displayStart: start,
				start: startSubtitle.start,
				end: endSubtitle.end,
				visible: true,
				keywords: keywords,
				searchTerm: keywords[0],
			};

			const filteredFootages = footages.filter((footage) => {
				return !(footage.start < endSubtitle.end && footage.end > startSubtitle.start);
			});

			const updatedFootages = [...filteredFootages, newFootage].sort((a, b) => a.start - b.start);

			setFootages(updatedFootages);

			const link = videos[index].url;
			// Précharger la vidéo
			// setLoadingVideos((prev) => [...prev, link]);
			// const { free, waitUntilDone } = prefetch(link);
			// waitUntilDone().then(() => {
			// 	console.log("Footage has finished prefetching");
			// 	setLoadingVideos((prev) => prev.filter((v) => v !== link));
			// });
			preloadVideo(link);
		}
	};

	const fetchVideos = async (keyword) => {
		try {
			const response = await axios.post("/pexels/fetch2", {
				keyword: keyword,
			});

			const data = response.data;
			if (data.videos && data.videos.length === 0) {
				return [];
			} else {
				return data.videos;
			}
		} catch (error) {
			return [];
		}
	};

	const fetchImages = async (keyword) => {
		try {
			const response = await axios.post("/pexels/fetchImages", {
				keyword: keyword,
			});

			const data = response.data;
			if (data.images && data.images.length === 0) {
				return [];
			} else {
				return data.images;
			}
		} catch (error) {
			return [];
		}
	};

	const handleSubtitleClick = (subtitle) => {
		if (!startSubtitle || subtitle.start <= startSubtitle.start) {
			setStartSubtitle(subtitle);
			setEndSubtitle(null);
		} else if (!endSubtitle || subtitle.start >= startSubtitle.start) {
			addHistory("footages", footages);
			setStartSubtitle(null);
			const start = startSubtitle.start;
			const end = subtitle.end;
			const overlappingFootages = getOverlappingFootages(start, end);
			const footagesFiltered = footages.filter((footage) => {
				return !overlappingFootages.includes(footage);
			});
			const newFootage = {
				id: uuidv4(),
				type: "None",
				videos: [],
				ai_images: [],
				images: [],
				backgrounds: {
					imagePath: "",
					path: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/grid.mp4",
					zoom: 0.6,
				},
				selectedVideo: 0,
				displayStart: start,
				start: start,
				end: end,
				visible: true,
			};
			const updatedFootages = [...footagesFiltered, newFootage];
			setFootages(updatedFootages.sort((a, b) => a.start - b.start));
		}
	};

	const resetStartSubtitle = () => {
		setStartSubtitle(null);
		setEndSubtitle(null);
	};

	const isSubtitleInAssignedFootage = (subtitle) => {
		return footages.some(
			(footage) => subtitle.start >= footage.start && subtitle.end <= footage.end
		);
	};

	const getFootageForSubtitle = (subtitle) => {
		return footages.find(
			(footage) => subtitle.start >= footage.start && subtitle.end <= footage.end
		);
	};

	const getSubtitlesForFootage = (footage) => {
		return subtitles.filter(
			(subtitle) => subtitle.start >= footage.start && subtitle.end <= footage.end
		);
	};

	const selectVideo = (footage, index) => {
		addHistory("footages", footages);
		const updatedFootages = footages.map((f) => {
			if (f === footage) {
				return {
					...f,
					selectedVideo: index,
				};
			}
			return f;
		});
		setFootages(updatedFootages);
		const link = footage.videos[index].url;
		// Précharger la vidéo
		// setLoadingVideos((prev) => [...prev, link]);
		// const { free, waitUntilDone } = prefetch(link);
		// waitUntilDone().then(() => {
		// 	console.log("Footage has finished prefetching");
		// 	setLoadingVideos((prev) => prev.filter((v) => v !== link));
		// });
		preloadVideo(link);
	};

	function assignSubtitleToFootage(subtitle, footage) {
		addHistory("footages", footages);
		let updatedFootage = { ...footage };

		if (updatedFootage.start > subtitle.start) {
			updatedFootage.start = subtitle.start;
		}
		if (updatedFootage.end < subtitle.end) {
			updatedFootage.end = subtitle.end;
		}

		const index = footages.findIndex((f) => f === footage);
		const updatedFootages = [...footages];
		updatedFootages[index] = updatedFootage;

		setFootages(updatedFootages);
	}

	const getPreviousFootage = (subtitle) => {
		return footages
			.filter((footage) => footage.end <= subtitle.start)
			.sort((a, b) => b.end - a.end)[0];
	};

	const getNextFootage = (currentSubtitle) => {
		// Trouve le premier footage qui commence après la fin de ce sous-titre
		return footages.find((footage) => footage.start >= currentSubtitle.end);
	};

	const [showSuggestions, setShowSuggestions] = useState(false);
	const [loadings, setLoadings] = useState([]);

	const hasUnassignedSubtitleBetween = (start, end) => {
		return subtitles.some((subtitle) => {
			const footageForThisSubtitle = getFootageForSubtitle(subtitle);
			return !footageForThisSubtitle && subtitle.start >= start && subtitle.end <= end;
		});
	};

	const loadMoreVideos = (footage) => {
		let updatedFootage = { ...footage };
		updatedFootage.loadCount += 12;
		setFootages((prevFootages) => prevFootages.map((f) => (f === footage ? updatedFootage : f)));
	};

	const renderLoading = useCallback(() => {
		return <AbsoluteFill className="bg-gray-400 animate-pulse"></AbsoluteFill>;
	}, []);

	const showFootage = (footage) => {
		const updatedFootages = footages.map((f) => {
			if (f.id === footage.id) {
				return {
					...f,
					showAll: true,
				};
			} else {
				return {
					...f,
					showAll: false,
				};
			}
		});
		setFootages(updatedFootages);
	};

	const hideFootage = (footage) => {
		const updatedFootages = footages.map((f) => {
			if (f.id === footage.id) {
				return {
					...f,
					showAll: false,
				};
			} else {
				return f;
			}
		});
		setFootages(updatedFootages);
	};
	console.log(footages);
	const renderSubtitles = (onlyAssigned = false) => {
		const elements = [];
		const handledSubtitles = new Set(); // Pour suivre quels sous-titres ont été traités

		subtitles.forEach((subtitle, indexSubtitle) => {
			if (handledSubtitles.has(subtitle.id)) return; // Ignorer les sous-titres déjà traités

			const footage = getFootageForSubtitle(subtitle);
			if (footage) {
				const associatedSubtitles = getSubtitlesForFootage(footage);

				const combinedTextElements = associatedSubtitles.map((associatedSubtitle, index) => {
					handledSubtitles.add(associatedSubtitle.id); // Marquer ce sous-titre comme traité

					return (
						<div key={associatedSubtitle.id} className="flex gap-1 px-2 text-gray-200">
							{/* Si c'est le premier ou le dernier sous-titre, ajouter le bouton "Print" */}
							{associatedSubtitle.words.map((word, index) => {
								return <span key={index}>{word.word + " "}</span>;
							})}
							{(index === 0 || index === associatedSubtitles.length - 1) && (
								<button
									className="text-gray-400"
									disabled={!globalActions?.footage?.activate}
									onClick={() => {
										addHistory("footages", footages);
										const start = associatedSubtitles[index].start;
										const end = associatedSubtitles[index].end;

										const oldFootage = { ...footage };
										const isSingleSubtitle = associatedSubtitles.length === 1;
										if (oldFootage.start <= start && oldFootage.end >= end) {
											if (index === 0) {
												oldFootage.start = end;
											} else if (index === associatedSubtitles.length - 1 && !isSingleSubtitle) {
												oldFootage.end = start;
											}
										}
										let updatedFootages;

										if (isSingleSubtitle) {
											updatedFootages = footages.filter((f) => f !== footage);
										} else {
											updatedFootages = footages.map((f) => (f === footage ? oldFootage : f));
										}

										setFootages(updatedFootages);
									}}
								>
									{index === 0 ? (
										<FaArrowUp className="text-xs" />
									) : (
										<FaArrowDown className="text-xs" />
									)}
								</button>
							)}
						</div>
					);
				});

				const duration = `${footage.start.toFixed(2)} - ${footage.end.toFixed(2)}`;

				const showClip = footage.type === "Clip" && footage.videos && footage.videos.length > 0;
				const showAI = footage.type === "AI" && footage.ai_images?.paths?.length > 0;
				const showImage =
					footage.type === "Background" && footage.backgrounds?.imagePath?.length > 0;

				elements.push(
					<div
						className={`group relative assigned-subtitle-block mb-2 rounded flex flex-col bg-gray-900 ${
							globalActions?.footage?.activate
								? footage.visible
									? ""
									: "opacity-30"
								: "opacity-30"
						}`}
					>
						<div
							className={`relative flex items-start mb-2 px-2 pt-1`}
							style={{
								minHeight: footage.videos && footage.videos.length > 0 ? "80px" : "0px",
							}}
						>
							<div className="flex flex-col pt-1">
								<DropdownSelection footage={footage} duration={duration} />
								<div className="flex pt-2">
									<div className="flex flex-col">
										{showClip ? (
											<div className="flex">
												<button
													className="relative flex-none bg-cover rounded mr-2"
													disabled={!globalActions?.footage?.activate}
													style={{
														backgroundImage: `url(${
															footage.videos[footage.selectedVideo]?.image || ""
														})`,
														width: "80px",
														height: "120px",
														backgroundSize: "cover",
														backgroundPosition: "center",
													}}
												>
													<button
														onClick={() => {
															setShowLayout(footage.id);
														}}
														className="absolute bottom-0 left-0 p-1 bg-gray-800 text-xs text-gray-200 w-full flex justify-center items-center gap-1 opacity-70 hover:opacity-90"
													>
														<FaCog />
														<span>Edit</span>
													</button>
												</button>
												<FootageLayout
													footage={footage}
													updateFootage={updateFootage}
													setShowLayout={setShowLayout}
													isOpen={showLayout === footage.id}
													setIsOpen={(open) => setShowLayout(open ? footage.id : null)}
												/>
												<div className="flex-grow pr-12">{combinedTextElements}</div>
											</div>
										) : showAI ? (
											<div className="flex">
												<button
													className="relative flex-none bg-cover rounded mr-2"
													disabled={!globalActions?.footage?.activate}
													style={{
														backgroundImage: `url(${
															footage.ai_images.paths[footage.ai_images?.selectedImage || 0] || ""
														})`,
														width: "80px",
														height: "120px",
														backgroundSize: "cover",
														backgroundPosition: "center",
													}}
												></button>
												<div className="flex-grow pr-12">{combinedTextElements}</div>
											</div>
										) : showImage ? (
											<div className="flex">
												<button
													className="relative flex-none bg-cover rounded mr-2"
													disabled={!globalActions?.footage?.activate}
													style={{
														backgroundImage: `url(${footage.backgrounds?.imagePath || ""})`,
														width: "80px",
														height: "120px",
														backgroundSize: "cover",
														backgroundPosition: "center",
													}}
												></button>
												<div className="flex-grow pr-12">{combinedTextElements}</div>
											</div>
										) : (
											<div className="relative mr-2">
												<div className="flex-grow pr-12">{combinedTextElements}</div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="absolute top-2 right-1">
								<div className="flex flex-row-reverse">
									<button
										disabled={!globalActions?.footage?.activate}
										onClick={() => {
											addHistory("footages", footages);
											let oldFootage = { ...footage };
											oldFootage.visible = !oldFootage.visible;
											const updatedFootages = footages.map((f) => (f === footage ? oldFootage : f));

											setFootages(updatedFootages);
										}}
										className={`ml-2 text-gray-200 p-1 rounded`}
									>
										{!footage.visible ? <BsEyeSlash /> : <BsEye />}
									</button>
									{footage.type == "Clip" && (
										<div className="relative flex flex-row-reverse gap-2 items-center">
											<input
												style={{
													opacity: loadings.includes(indexOf(footages, footage)) ? 0.5 : 1,
												}}
												type="text"
												disabled={loadings.includes(indexOf(footages, footage))}
												value={
													loadings.includes(indexOf(footages, footage))
														? "Loading..."
														: footage.searchTerm
												}
												onChange={(e) => {
													let updatedFootage = { ...footage };
													updatedFootage.searchTerm = e.target.value;
													const updatedFootages = footages.map((f) =>
														f === footage ? updatedFootage : f
													);
													setFootages(updatedFootages);
												}}
												onKeyDown={async (e) => {
													if (e.key === "Enter") {
														addHistory("footages", footages);
														setShowSuggestions(null);
														setLoadings((prev) => [...prev, indexOf(footages, footage)]);
														const videos = await fetchVideos(footage.searchTerm);
														setLoadings((prev) =>
															prev.filter((l) => l !== indexOf(footages, footage))
														);
														if (!videos || videos.length === 0) {
															toast.error("No videos found for this keyword");
															return;
														}
														let updatedFootage = { ...footage };
														const uploadedVideos =
															updatedFootage?.videos?.length > 0
																? updatedFootage?.videos?.filter((video) => video.uploaded)
																: [];
														videos.unshift(...uploadedVideos);
														updatedFootage.videos = videos;
														updatedFootage.selectedVideo = 0;
														updatedFootage.showAll = true;
														const keywords = footage.keywords || [];
														let byUser = false;
														if (!keywords.includes(footage.searchTerm)) {
															byUser = true;
														}
														updatedFootage.byUser = byUser;
														const updatedFootages = footages.map((f) =>
															f === footage ? updatedFootage : { ...f, showAll: false }
														);
														setFootages(updatedFootages);
													}
												}}
												placeholder="Search videos..."
												className="flex-grow p-1 lg:p-1 text-[11px] lg:text-[12px] w-24 lg:w-28 bg-gray-800 rounded text-gray-200"
												onFocus={() => setShowSuggestions(indexOf(footages, footage))}
												onBlur={() => {
													setShowSuggestions(null);
												}}
											/>
											{loadings.includes(indexOf(footages, footage)) && (
												<div className="absolute top-1 right-1 w-[20px] h-[20px] bg-opacity-70 flex items-center justify-center">
													<div className="animate-spin w-4 h-4 border-t-2 border-gray-200 rounded-full"></div>
												</div>
											)}
											{footage.keywords &&
												footage.keywords.length > 0 &&
												showSuggestions === indexOf(footages, footage) && (
													<div className="absolute bg-gray-800 text-gray-200 rounded p-1 mt-1 w-24 lg:w-28 top-full max-h-[200px] overflow-auto">
														{[...footage.keywords].reverse().map((keyword, index) => (
															<div
																key={index}
																className="cursor-pointer hover:bg-gray-700 p-1 rounded text-[10px]"
																onMouseDown={async (e) => {
																	e.preventDefault();
																	addHistory("footages", footages);
																	let updatedFootage = {
																		...footage,
																		searchTerm: keyword,
																	};
																	const updatedFootages = footages.map((f) =>
																		f === footage ? updatedFootage : f
																	);

																	setFootages(updatedFootages);
																	setShowSuggestions(null);
																}}
															>
																{keyword}
															</div>
														))}
													</div>
												)}
											<div className="w-[1px] h-[20px] bg-gray-600"></div>
											<FootageUpload footage={footage} updateFootage={updateFootage} />
										</div>
									)}
									{footage.type == "Background" && (
										<div className="relative flex flex-row-reverse gap-2 items-center">
											<input
												style={{
													opacity: loadings.includes(indexOf(footages, footage)) ? 0.5 : 1,
												}}
												type="text"
												disabled={loadings.includes(indexOf(footages, footage))}
												value={
													loadings.includes(indexOf(footages, footage))
														? "Loading..."
														: footage.searchTerm
												}
												onChange={(e) => {
													let updatedFootage = { ...footage };
													updatedFootage.searchTerm = e.target.value;
													const updatedFootages = footages.map((f) =>
														f === footage ? updatedFootage : f
													);
													setFootages(updatedFootages);
												}}
												onKeyDown={async (e) => {
													if (e.key === "Enter") {
														addHistory("footages", footages);
														setShowSuggestions(null);
														setLoadings((prev) => [...prev, indexOf(footages, footage)]);
														const images = await fetchImages(footage.searchTerm);
														setLoadings((prev) =>
															prev.filter((l) => l !== indexOf(footages, footage))
														);
														if (!images || images.length === 0) {
															toast.error("No images found for this keyword");
															return;
														}
														let updatedFootage = { ...footage };
														const uploadedImages =
															updatedFootage?.images?.length > 0
																? updatedFootage?.images?.filter((image) => image.uploaded)
																: [];
														//add uploaded images to the images array at the beginning
														images.unshift(...uploadedImages);
														updatedFootage.images = images;
														updatedFootage.backgrounds = {
															...updatedFootage.backgrounds,
															imagePath: images[0].url,
														};
														updatedFootage.showAll = true;
														const updatedFootages = footages.map((f) =>
															f === footage ? updatedFootage : { ...f, showAll: false }
														);
														setFootages(updatedFootages);
													}
												}}
												placeholder="Search images..."
												className="flex-grow p-1 lg:p-1 text-[11px] lg:text-[12px] w-24 lg:w-28 bg-gray-800 rounded text-gray-200"
												onFocus={() => setShowSuggestions(indexOf(footages, footage))}
												onBlur={() => setShowSuggestions(null)}
											/>
											{loadings.includes(indexOf(footages, footage)) && (
												<div className="absolute top-1 right-1 w-[20px] h-[20px] bg-opacity-70 flex items-center justify-center">
													<div className="animate-spin w-4 h-4 border-t-2 border-gray-200 rounded-full"></div>
												</div>
											)}
											{footage.keywords &&
												footage.keywords.length > 0 &&
												showSuggestions === indexOf(footages, footage) && (
													<div className="absolute bg-gray-800 text-gray-200 top-full rounded p-1 mt-1 w-24 lg:w-28 max-h-[200px] overflow-auto">
														{[...footage.keywords].reverse().map((keyword, index) => (
															<div
																key={index}
																className="cursor-pointer hover:bg-gray-700 p-1 rounded text-[10px]"
																onMouseDown={async (e) => {
																	e.preventDefault();
																	addHistory("footages", footages);
																	let updatedFootage = {
																		...footage,
																		searchTerm: keyword,
																	};
																	const updatedFootages = footages.map((f) =>
																		f === footage ? updatedFootage : f
																	);

																	setFootages(updatedFootages);
																	setShowSuggestions(null);
																}}
															>
																{keyword}
															</div>
														))}
													</div>
												)}
											<div className="w-[1px] h-[20px] bg-gray-600"></div>
											<FootageUpload footage={footage} updateFootage={updateFootage} />
											{/* <label className="bg-gray-800 text-gray-200 px-2 py-1 rounded cursor-pointer">
												<input
													type="file"
													accept="image/*"
													className="hidden"
													onChange={async (e) => {
														const file = e.target.files[0];
														const url = await uploadBackgroundImage(file);
														if (url) {
															let updatedFootage = { ...footage };
															updatedFootage.backgrounds = {
																paths: [...(updatedFootage.backgrounds?.paths || []), url],
																selectedBackground: (updatedFootage.backgrounds?.paths || [])
																	.length,
															};
															updatedFootage.showAll = true;
															const updatedFootages = footages.map((f) =>
																f === footage ? updatedFootage : f
															);
															setFootages(updatedFootages);
														}
													}}
												/>
												Upload
											</label> */}
										</div>
									)}
								</div>
							</div>
						</div>

						<div style={{ backgroundColor: "rgba(3,7,18,0.35)" }} className={`w-full`}>
							{footage.type === "Clip" && (
								<div className="">
									<div className="w-full flex">
										<div className="flex-shrink-0 opacity-100 lg:opacity-0 group-hover:opacity-100">
											<FootageTransitions footage={footage} />
											<FootageSoundEffects footage={footage} />
										</div>
										{footage.videos && footage.videos.length > 0 && (
											<button
												className="pl-2 mr-2 flex-grow"
												onClick={() => {
													footage.showAll ? hideFootage(footage) : showFootage(footage);
												}}
											>
												<div className="text-gray-400 flex justify-end items-center gap-2 group-hover:text-gray-200 group-hover:font-bold">
													{!footage.showAll && footage.videos && footage.videos.length > 0 && (
														<div className="text-xs text-gray-400 opacity-0 group-hover:opacity-100 font-semibold">
															{footage.videos.length} videos
														</div>
													)}
													{footage.showAll ? <FaArrowDown /> : <FaArrowLeft />}
												</div>
											</button>
										)}
									</div>
									{footage.showAll && footage.videos && footage.videos.length > 0 && (
										<div className="pt-1 overflow-x-auto pb-2 px-2">
											<div className="flex">
												{footage.videos.map((video, idx) => (
													<div key={idx} className="relative mr-2 flex-shrink-0">
														<img
															loading="lazy"
															className={`h-28 w-auto cursor-pointer rounded ${
																idx === footage.selectedVideo ? "" : ""
															}`}
															src={video.image}
															alt="video thumbnail"
															onClick={() => selectVideo(footage, idx)}
															style={{
																boxShadow:
																	idx === footage.selectedVideo
																		? "0 0 0 2px rgb(59 130 246)"
																		: "none",
																width: "auto",
																maxWidth: "none",
															}}
														/>
														{video?.author?.url && video?.author?.name && (
															<div className="absolute bottom-0 left-0 right-0 h-3 bg-black bg-opacity-50 flex items-center justify-center">
																<a
																	href={video.author.url}
																	target="_blank"
																	rel="noopener noreferrer"
																	className="text-[10px] text-white truncate"
																	onClick={(e) => e.stopPropagation()}
																>
																	{video.author.name}
																</a>
															</div>
														)}
													</div>
												))}
											</div>
										</div>
									)}
									{footage.showAll && footage.videos && footage.videos.length > 0 && (
										<div className="text-sm text-gray-200 py-2 pl-2">
											Videos provided by{" "}
											<a
												href="https://www.pexels.com"
												target="_blank"
												rel="noopener noreferrer"
												className="underline text-blue-500"
											>
												Pexels
											</a>
										</div>
									)}
								</div>
							)}

							{footage.type === "Zoom" && (
								<div className="">
									<FootageZoom
										footage={footage}
										updateFootage={updateFootage}
										showFootage={showFootage}
										hideFootage={hideFootage}
										zoomModels={zoomModels}
										whooshModels={whooshModels}
										videoLink={videoLink}
										setSelectZoomFootageId={setSelectZoomFootageId}
									/>
								</div>
							)}

							{footage.type === "AI" && (
								<div className="">
									<FootageAI
										footage={footage}
										updateFootage={updateFootage}
										showFootage={showFootage}
										hideFootage={hideFootage}
										transcript={transcript}
										associatedSubtitles={associatedSubtitles}
									/>
								</div>
							)}
							{footage.type === "Background" && (
								<div className="">
									<FootageBackground
										footage={footage}
										updateFootage={updateFootage}
										showFootage={showFootage}
										hideFootage={hideFootage}
										transcript={transcript}
										associatedSubtitles={associatedSubtitles}
									/>
								</div>
							)}

							{footage.type === "None" && (
								<div style={{ backgroundColor: "rgba(3,7,18,0.35)" }} className={`w-full`}>
									<FootageTransitions footage={footage} />
									<FootageSoundEffects footage={footage} />
								</div>
							)}
						</div>
					</div>
				);
			} else if (!onlyAssigned) {
				const nextFootage = getNextFootage(subtitle);
				const previousFootage = getPreviousFootage(subtitle);

				const canAssignToNext =
					nextFootage && !hasUnassignedSubtitleBetween(subtitle.end, nextFootage.start);
				const canAssignToPrevious =
					previousFootage && !hasUnassignedSubtitleBetween(previousFootage.end, subtitle.start);

				elements.push(
					<div
						className={`unassigned-subtitle flex flex-col items-center justify-center space-y-2`}
					>
						{canAssignToPrevious && (
							<button
								onClick={() => {
									assignSubtitleToFootage(subtitle, previousFootage);
								}}
								className={` text-gray-400 rounded-full px-2 ${
									globalActions?.footage?.activate ? "" : "opacity-30"
								}`}
								disabled={!globalActions?.footage?.activate}
							>
								<FaArrowUp className="text-xs" />
							</button>
						)}

						<button
							onClick={() => handleSubtitleClick(subtitle)}
							className={`block w-full bg-gray-900 rounded text-gray-200 px-2 py-1 mb-1
            ${startSubtitle === subtitle ? "bg-green-500" : ""} 
            ${endSubtitle === subtitle ? "bg-red-500" : ""} ${
								globalActions?.footage?.activate ? "" : "opacity-30"
							}`}
							disabled={!globalActions?.footage?.activate}
						>
							{subtitle.words.map((word) => word.word).join(" ")}
						</button>

						{canAssignToNext && (
							<button
								onClick={() => {
									assignSubtitleToFootage(subtitle, nextFootage);
								}}
								className={`text-gray-400 rounded-full px-2 mb-2 ${
									globalActions?.footage?.activate ? "" : "opacity-30"
								}`}
								disabled={!globalActions?.footage?.activate}
							>
								<FaArrowDown className="text-xs" />
							</button>
						)}
					</div>
				);
			}
		});

		return elements;
	};

	function toggleFootageActivation() {
		// addHistory("globalActions", globalActions);
		let newStatus;
		if (globalActions?.footage) {
			newStatus = !globalActions.footage.activate;
		} else {
			newStatus = true;
		}
		const updatedGlobalActions = {
			...globalActions,
			footage: {
				...globalActions.footage,
				activate: newStatus,
			},
		};
		setGlobalActions(updatedGlobalActions);
	}

	const [showSmartFootage, setShowSmartFootage] = useState(true);

	function SwitchButton({ option, disabled }) {
		return (
			<button
				className={`flex items-center px-[2.5px] w-9 h-[14px] rounded-xl ${
					optionsFootage[option] ? "justify-end bg-blue-500" : "justify-start bg-red-500"
				} ${disabled ? "disabled opacity-50 cursor-not-allowed" : ""}`}
				onClick={() => {
					if (disabled) return;
					const updatedOptionsFootage = {
						...optionsFootage,
						[option]: !optionsFootage[option],
					};
					setOptionsFootage(updatedOptionsFootage);
				}}
				disabled={disabled}
			>
				<div className="bg-white w-[10px] h-[10px] rounded-full shadow-md"></div>
			</button>
		);
	}

	const selectedLayoutFootage = footages.find((f) => f.id === showLayout);

	return (
		<>
			{!isGenerating ? (
				<>
					<div className="flex gap-1 w-full mb-4">
						{globalActions?.footage?.activate ? (
							<div className="flex flex-col gap-4 w-full">
								<button
									onClick={() => toggleFootageActivation()}
									className="bg-red-500 hover:bg-red-600 rounded text-gray-200 px-2 py-1 w-full"
								>
									Deactivate
								</button>
								<div className="bg-gray-900 text-gray-200 w-full rounded">
									{showSmartFootage ? (
										<div className="relative px-3 py-2">
											<button
												className="absolute top-3 right-3 text-gray-400 hover:text-gray-200"
												onClick={() => setShowSmartFootage(false)}
											>
												<FaArrowDown />
											</button>
											<span className="text-gray-200 font-semibold mb-3">🤖 Smart footage</span>
											<div className="flex items-center gap-2">
												<span className="block text-[14px]">🎞️ Perfect clip integration</span>
												<SwitchButton option={"clip"} />
											</div>
											<span className="text-xs text-gray-400 mb-1">
												Automatically add perfect clips to your video
											</span>
											<div className="text-xs text-gray-400 mb-3">
												Videos provided by{" "}
												<a
													href="https://www.pexels.com"
													className="underline text-blue-500"
													target="_blank"
													rel="noopener noreferrer"
												>
													Pexels
												</a>
											</div>

											<div className="flex items-center gap-2">
												<span className="block text-[14px]">🌟 Automatic transition</span>
												<SwitchButton option={"transition"} />
											</div>
											<span className="text-xs text-gray-400 mb-3">
												Add trendy transitions between clips
											</span>

											<div className="flex items-center gap-2">
												<span className="block text-[14px]">🔎 Smart zooming</span>
												<SwitchButton option={"zoom"} />
											</div>
											<span className="text-xs text-gray-400 mb-3">
												Automatically zoom on the most important part of the clip
											</span>

											<button
												onClick={() => {
													generateFootage();
													setShowSmartFootage(false);
												}}
												className=" bg-blue-500 hover:bg-blue-600 block rounded text-gray-200 font-semibold px-2 py-2 mb-1 w-full"
											>
												<FaRobot className="inline mr-1" /> Generate
											</button>
										</div>
									) : (
										<button
											className="flex w-full items-center justify-between px-3 py-2"
											onClick={() => {
												setShowSmartFootage(true);
											}}
										>
											<span className="text-gray-200 font-semibold">🤖 Smart footage</span>
											<button className="text-gray-400 hover:text-gray-200">
												<FaArrowLeft />
											</button>
										</button>
									)}
								</div>
							</div>
						) : (
							<button
								onClick={() => toggleFootageActivation()}
								className={`bg-green-500 hover:bg-green-600 rounded text-gray-200 px-2 py-1 ${
									globalActions?.footage?.activate ? "w-1/2" : "w-full"
								}`}
							>
								Activate
							</button>
						)}
					</div>
					{renderSubtitles(false)}
					{showMenu && (
						<div
							style={{ zIndex: 999999 }}
							className="fixed top-0 left-0 w-screen h-screen flex flex-row-reverse items-center justify-center bg-opacity-50 bg-black backdrop-filter backdrop-blur-sm"
						>
							{/* <div className="w-[30%] px-4 pb-4 max-h-[80vh] overflow-y-auto">
							{startSubtitle && endSubtitle ? (
								<div className="bg-green-500 text-white rounded p-2">
									{subtitles
										.slice(
											subtitles.indexOf(startSubtitle),
											subtitles.indexOf(endSubtitle) + 1
										)
										.map((subtitle, index) => (
											<span className="mr-[4px]" key={index}>
												{subtitle.value}
											</span>
										))}
								</div>
							) : (
								renderSubtitles()
							)}
						</div> */}

							<div
								className={`${
									selectedVideo ? "w-full h-full lg:h-auto lg:max-w-screen-lg" : ""
								} gap-4 relative flex flex-col lg:flex-col items-start p-4 bg-gray-800 rounded-lg shadow-lg opacity-80`}
							>
								<div className="w-full">
									{selectedVideo && startSubtitle && endSubtitle && (
										<div className="w-full flex gap-2 lg:hidden mb-2">
											<button
												onClick={() => {
													addToFootages(videos, startIndex, []);
													setStartSubtitle(null);
													setEndSubtitle(null);
													setSelectedVideo(null);
													setShowMenu(false);
												}}
												className="bg-green-500 rounded text-white text-sm px-2 py-1 hover:bg-green-600 transition duration-300"
											>
												Validate
											</button>
											<button
												onClick={() => {
													setShowMenu(false);
													setStartSubtitle(null);
													setEndSubtitle(null);
													setSelectedVideo(null);
												}}
												className="bg-red-500 rounded text-white text-sm px-2 py-1 hover:bg-green-600 transition duration-300"
											>
												Cancel
											</button>
										</div>
									)}
									<button
										onClick={() => {
											setShowMenu(false);
											setStartSubtitle(null);
											setEndSubtitle(null);
											setSelectedVideo(null);
										}}
										className="hidden lg:block mb-2 lg:mb-4 bg-red-500 text-white rounded-full px-2 hover:bg-red-600 transition duration-300"
									>
										&times; {/* Represents the close icon */}
									</button>

									<span className="hidden lg:flex items-center mb-1 lg:mb-2 text-sm lg:text-lg font-bold text-white">
										✏️ Add footage to the following subtitle:
									</span>

									{startSubtitle && endSubtitle && (
										<div className="bg-gray-900 hidden lg:block text-white rounded p-2 mb-4">
											{subtitles
												.slice(subtitles.indexOf(startSubtitle), subtitles.indexOf(endSubtitle) + 1)
												.map((subtitle, index) => (
													<span className="mr-[4px]" key={index}>
														{subtitle.words.map((word, index) => {
															return <span key={index}>{word.word + " "}</span>;
														})}
													</span>
												))}
										</div>
									)}

									{statusMessage && (
										<span className="mb-4 text-white bg-red-300 p-2 rounded ">{statusMessage}</span>
									)}

									<span className="hidden lg:flex items-center mb-1 lg:mb-2 text-sm lg:text-lg font-bold text-white">
										🤖 Smart Keywords:
									</span>

									<div className="flex items-center mb-2 lg:mb-4">
										<span className="block lg:hidden text-sm text-white font-semibold mr-1">
											🤖 Keywords:
										</span>
										{startSubtitle &&
											endSubtitle &&
											subtitles
												.slice(subtitles.indexOf(startSubtitle), subtitles.indexOf(endSubtitle) + 1)
												.map((subtitle) => {
													const footage = getFootageForSubtitle(subtitle);
													return footage && footage.keywords && footage.keywords.length > 0
														? footage.keywords
														: [];
												})
												.flat() // Flatten the array of arrays
												.filter((keyword, index, self) => self.indexOf(keyword) === index) // Remove duplicates
												.map((keyword, index) => (
													<span
														key={index}
														className="text-white bg-blue-400 rounded px-2 py-1 mr-2 mb-1 cursor-pointer text-xs lg:text-md"
														onClick={() => setSearchTerm(keyword)}
													>
														{keyword}
													</span>
												))}
									</div>

									<div className="mb-2 lg:mb-4 flex gap-2 items-center">
										<input
											type="text"
											value={searchTerm}
											onChange={(e) => setSearchTerm(e.target.value)}
											placeholder="Search videos..."
											className="flex-grow p-1 lg:p-2 text-sm lg:text-sm rounded border"
										/>
										<button
											onClick={fetchVideos}
											className="bg-blue-500 text-white rounded text-sm lg:text-md px-2 lg:px-4 py-2 hover:bg-blue-600 transition duration-300"
										>
											Search
										</button>
									</div>

									<div className="h-[full]">
										{selectedVideo && startSubtitle && endSubtitle && (
											<div>
												<div className="w-full flex justify-between">
													<button
														onClick={() => {
															addToFootages(videos, startIndex, []);
															setStartSubtitle(null);
															setEndSubtitle(null);
															setSelectedVideo(null);
															setShowMenu(false);
														}}
														className="bg-green-500 rounded text-white px-4 py-2 hover:bg-green-600 transition duration-300"
													>
														Validate
													</button>
												</div>
											</div>
										)}
									</div>
								</div>

								{selectedVideo || isLoading ? (
									<div className="w-full">
										<div className="flex justify-center gap-4 mb-4 items-stretch">
											{/* <div className="flex w-48 lg:w-[55%] lg:items-stretch rounded overflow-hidden">
											{isLoading ? (
												<div className="bg-gray-200 animate-pulse w-64 h-[416px]"></div>
											) : (
												<video
													key={selectedVideo.url}
													width="100%"
													height="100%"
													muted
													controls
												>
													<source
														src={selectedVideo.url}
														type="video/mp4"
													/>
													Your browser does not support the video tag.
												</video>
											)}
										</div> */}
											<div className="flex flex-row overflow-x-scroll scrollbar-hide w-full">
												<div className="flex gap-2">
													{isLoading
														? [...Array(4)].map((_, index) => (
																<div
																	key={index}
																	className="w-24 h-48 object-cover rounded bg-gray-200 animate-pulse"
																></div>
														  ))
														: videos.map((video, index) => (
																<button
																	key={index}
																	disabled={!globalActions?.footage?.activate}
																	onClick={() => {
																		setSelectedVideo(video);
																	}}
																	className={`relative cursor-pointer transition duration-300 w-24 ${
																		selectedVideo === video
																			? "border-blue-500 shadow-md"
																			: "hover:shadow-md"
																	}`}
																>
																	<img
																		className="w-full h-full object-cover rounded"
																		src={video.image}
																		alt="Image prévisualisation"
																	/>
																	<div
																		className="hidden lg:block absolute bottom-0 left-0 right-0 "
																		onClick={() => {
																			//redirect to the video page
																			window.open(video.user.url, "_blank");
																		}}
																	>
																		<div className="text-gray-200 text-sm px-2 py-1 bg-gray-900 rounded-bl rounded-br flex items-center justify-center w-full">
																			{video.user.name}
																		</div>
																	</div>
																</button>
														  ))}
												</div>
											</div>
										</div>
									</div>
								) : null}
							</div>
						</div>
					)}
				</>
			) : (
				<div className="flex justify-center pt-2">
					<div className="w-64 flex flex-col items-center text-center p-4 bg-gray-900 rounded-xl border-2 border-gray-700 text-gray-200">
						<FaRobot className="animate-bounce text-[24px] mb-2 mr-1" />
						<span className="text-sm text-gray-400">
							We are generating perfect footages for your video...
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default FootageMenu;
